import axios from 'axios'
import Cookies from 'js-cookie'

import { getContentTpe } from '@/api/api.helper'
import instance from '@/api/api.interceptor'
import { IAuthResponse } from '@/types/user'
import { IEmailPassword, IUserDto } from '@/types/user'

import { saveToStorage } from './auth.helper'

const AuthService = {
  async login(data: IEmailPassword) {
    const response = await instance<IAuthResponse>({
      url: `/auth/login`,
      method: 'POST',
      data
    })

    if (response.data.accessToken) {
      saveToStorage(response.data)
    }

    return response.data
  },
  async register(data: IUserDto) {
    const response = await instance<IAuthResponse>({
      url: `/auth/register`,
      method: 'POST',
      data
    })

    return response.data
  },
  async getNewTokens() {
    const refreshToken = Cookies.get('refreshToken')

    const response = await axios.post<string, { data: IAuthResponse }>(
      process.env.API_URL + '/auth/login/access-token',
      { refreshToken },
      { headers: getContentTpe() }
    )

    if (response.data.accessToken) {
      saveToStorage(response.data)
    }

    return response
  }
}

export default AuthService

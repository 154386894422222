import { FC } from 'react'
import MainLayout from '@/components/MainLayout'
import { useParams } from 'react-router'

import {
  Container,
  Content,
  Title,
  ProductTransitionsList,
  ProductTransitionItem,
  ProductName,
  ProductAmount,
  ProductTransitionsHeader,
  ProductTransitionsHeaderItem,
  ApprovedText,
  ApprovedIcon
} from './styles'
import { Icon24 } from '@/components/Icon'
import useProductTransitionsReportById from '@/hooks/productTransitionsReports/useProductTransitionsReportById'
import { format } from 'date-fns'
import useProducts from '@/hooks/products/useProducts'

const NewProductTransition: FC = () => {
  const { reportId } = useParams<{ reportId: string }>()
  const { report } = useProductTransitionsReportById(reportId || null)
  const { products } = useProducts()

  const productTypeUnit = (type: 'weight' | 'volume' | 'count') =>
    ({
      weight: 'кг',
      volume: 'л',
      count: 'шт'
    }[type])

  if (!report || !products) return null
  return (
    <MainLayout>
      <Container>
        <Content>
          <ApprovedText
            approved={
              report.status !== 'pending' && report.status !== 'editing'
            }
          >
            <ApprovedIcon
              approved={
                report.status !== 'pending' && report.status !== 'editing'
              }
            >
              <Icon24.Chevron />
            </ApprovedIcon>
            {report.status === 'approved'
              ? 'Подтверждено'
              : 'Ожидает подтверждения'}
          </ApprovedText>
          <Title>{format(new Date(report.date), 'dd.MM.yyyy')}</Title>
          {!!report.productTransitions.length && (
            <ProductTransitionsHeader>
              <ProductTransitionsHeaderItem>
                Продукт
              </ProductTransitionsHeaderItem>
              <ProductTransitionsHeaderItem>
                Количество
              </ProductTransitionsHeaderItem>
              <ProductTransitionsHeaderItem>
                Исправлено
              </ProductTransitionsHeaderItem>
            </ProductTransitionsHeader>
          )}
          <ProductTransitionsList>
            {report.productTransitions.map(productTransition => (
              <ProductTransitionItem key={productTransition.product.id}>
                <ProductName>
                  {
                    products.find(
                      product => product.id === productTransition.product.id
                    )?.name
                  }
                </ProductName>
                <ProductAmount>
                  {productTransition.amount}
                  {productTypeUnit(
                    products.find(
                      product => product.id === productTransition.product.id
                    )?.unit || 'weight'
                  )}
                </ProductAmount>
                <ProductAmount>
                  {productTransition.updatedAmount !== null
                    ? `${productTransition.updatedAmount}${productTypeUnit(
                        products.find(
                          product => product.id === productTransition.product.id
                        )?.unit || 'weight'
                      )}`
                    : ''}
                </ProductAmount>
              </ProductTransitionItem>
            ))}
          </ProductTransitionsList>
        </Content>
      </Container>
    </MainLayout>
  )
}

export default NewProductTransition

import ProductTransitionsReportService from '@/services/productTransitionsReport/product.transition.service'
import { useQuery } from '@tanstack/react-query'

const useProductTransitionsReportById = (id: string | null) => {
  const { isLoading, data } = useQuery(
    ['product transitions report by id'],
    async () => {
      return ProductTransitionsReportService.getById(id)
    }
  )

  return { isLoading, report: data?.data }
}

export default useProductTransitionsReportById

import Button from '@/components/Button'
import { FC, useEffect, useState } from 'react'
import { Container, Content, Title, Inputs } from './styles'
import { IUserDto } from '@/types/user'
import useUserById from '@/hooks/users/useUserById'
import useUpdateUser from '@/hooks/users/useUpdateUser'
import MainLayout from '@/components/MainLayout'
import useUserUrlId from '@/hooks/users/useUserUrlId'
import { useNavigate } from 'react-router'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import EditUserForm from '@/components/Forms/EditUserForm'

const EditUser: FC = () => {
  const id = useUserUrlId()
  const restaurantId = useRestaurantUrlId()
  const navigate = useNavigate()

  const [currentUser, setCurrentUser] = useState<IUserDto>({
    name: '',
    email: '',
    role: undefined,
    rate: 0,
    restaurantId: 0
  })
  const { user, isLoading } = useUserById(id)

  const updateUser = useUpdateUser(id, currentUser, () =>
    navigate(`/restaurants/${restaurantId}/users`)
  )

  useEffect(() => {
    if (!isLoading && user) {
      setCurrentUser(user)
    }
  }, [user, isLoading])

  const handleButtonClick = async () => {
    if (!currentUser.name || !currentUser.email) return
    updateUser()
  }

  return (
    <MainLayout>
      <Container>
        <Content>
          <Title>Редактирование сотрудника</Title>
          <EditUserForm currentUser={currentUser} onChange={setCurrentUser} />
          <Button onClick={handleButtonClick}>Обновить</Button>
        </Content>
      </Container>
    </MainLayout>
  )
}

export default EditUser

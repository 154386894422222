import ProductTransitionsReportService from '@/services/productTransitionsReport/product.transition.service'
import { useMutation } from '@tanstack/react-query'

const useCreateProductTransitionsReport = (
  id: string | null,
  onSuccess: (data: any) => void
) => {
  const { mutate } = useMutation(
    ['create report'],
    () => ProductTransitionsReportService.create(id),
    {
      onSuccess
    }
  )

  return mutate
}

export default useCreateProductTransitionsReport

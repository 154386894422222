import React, { useEffect } from 'react'

import MainLayout from '@/components/MainLayout'
import StatisticsPanel from '@/components/StatisticsPanel'
// import Chart from '@/components/Chart'
// import Colors from '@/shared/colors'

import {
  Container,
  TimePeriods,
  TimePeriod,
  StatisticsWrapper,
  Menu,
  MenuItem,
  MenuItemTitle
  // Charts
} from './styles'
import { useLocation, useNavigate } from 'react-router'
import { NavigationItem, navigation } from '@/components/MainLayout/navigation'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import { useAuth } from '@/hooks/useAuth'

const Dashboard: React.FC = () => {
  const navigate = useNavigate()
  const restaurantId = useRestaurantUrlId()
  const { user, isLoading } = useAuth()
  const [navigationItems, setNavigationItems] = React.useState<
    NavigationItem[]
  >([])

  useEffect(() => {
    if (!isLoading && user) {
      setNavigationItems(
        navigation(restaurantId, user?.role || 'operator').filter(
          item => item.name !== 'Главная'
        )
      )
    }
  }, [isLoading, user])
  // const navigationItems = navigation(
  //   restaurantId,
  //   user?.role || 'operator'
  // ).filter(item => item.name !== 'Главная')

  return (
    <MainLayout>
      <Container>
        {/* <TimePeriods>
          <TimePeriod>День</TimePeriod>
          <TimePeriod isActive>Неделя</TimePeriod>
          <TimePeriod>Месяц</TimePeriod>
        </TimePeriods>

        <StatisticsWrapper>
          <StatisticsPanel />
        </StatisticsWrapper> */}

        {/* <Charts>
          <Chart
            title="Трафик"
            values={[150, 200, 175, 210, 215, 296]}
            labels={['1.10', '7.10', '14.10', '21.10', '28.10', '4.11']}
            color={Colors.Blue}
          />
          <Chart
            title="Уникальные клиенты"
            values={[34, 56, 28, 74, 61]}
            labels={['1.10', '7.10', '14.10', '21.10', '28.10', '4.11']}
            color={Colors.Green}
          />
        </Charts> */}
        <Menu>
          {navigationItems &&
            navigationItems.map((item, index) => (
              <MenuItem key={item.url} onClick={() => navigate(item.url)}>
                {/* <item.icon /> */}
                <MenuItemTitle>{item.name}</MenuItemTitle>
              </MenuItem>
            ))}
        </Menu>
      </Container>
    </MainLayout>
  )
}

export default Dashboard

import React, { FC, useState } from 'react'
import { Button, Container } from './styles'

export type RadioButtonOption = {
  value: string
  label: string
}

interface RadioButtonsProps {
  options: RadioButtonOption[]
  onChange(option: RadioButtonOption): void
}

const RadioButtons: FC<RadioButtonsProps> = ({ options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<RadioButtonOption>(
    options[0]
  )

  const handleSelectOption = (option: RadioButtonOption) => {
    setSelectedOption(option)
    onChange(option)
  }

  return (
    <Container>
      {options.map(option => (
        <Button
          key={option.value}
          isActive={selectedOption.value === option.value}
          onClick={() => handleSelectOption(option)}
        >
          {option.label}
        </Button>
      ))}
    </Container>
  )
}

export default RadioButtons

import Button from '@/components/Button'
import { FC, useEffect, useState } from 'react'
import { Container, Content, Title } from './styles'
import MainLayout from '@/components/MainLayout'
import { useNavigate, useParams } from 'react-router'
import useProductById from '@/hooks/products/useProductById'
import useUpdateProduct from '@/hooks/products/useUpdateProduct'
import { IProductDto } from '@/types/product'
import EditProductForm from '@/components/Forms/EditProductForm'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'

const EditProduct: FC = () => {
  let { productId } = useParams<{ productId: string }>()
  const navigate = useNavigate()
  const restaurantId = useRestaurantUrlId()

  const [currentProduct, setCurrentProduct] = useState<IProductDto>({
    name: '',
    type: 'kitchen',
    price: 0,
    unit: 'weight',
    imagePath: ''
  })
  const { product, isLoading } = useProductById(productId || null)

  const updateProduct = useUpdateProduct(
    productId || null,
    currentProduct,
    () => navigate(`/restaurants/${restaurantId}/products`)
  )

  useEffect(() => {
    if (!isLoading && product) {
      setCurrentProduct(product)
    }
  }, [product, isLoading])

  const handleButtonClick = async () => {
    if (!currentProduct.name) return
    updateProduct()
  }

  return (
    <MainLayout>
      <Container>
        <Content>
          <Title>Редактирование продукта</Title>
          <EditProductForm
            currentProduct={currentProduct}
            onChange={setCurrentProduct}
          />
          <Button onClick={handleButtonClick}>Обновить</Button>
        </Content>
      </Container>
    </MainLayout>
  )
}

export default EditProduct

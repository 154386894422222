import MainLayout from '@/components/MainLayout'
import { useProductReceivedTransitionsReportsByPeriod } from '@/hooks/productTransitionsReports/useProductReceivedTransitionsReports'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import React, { FC, useMemo } from 'react'
import {
  Container,
  DatePickerWrapper,
  DatePickers,
  Divier,
  Title,
  Header
} from './styles'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { SpinnerAligned } from '@/components/Spinner/Spinner'
import ProductTransitionsTable from '@/components/Tables/ProductTransitionsTable'
import Button from '@/components/Button'
import jsPDF from 'jspdf'
import { fontBase64 } from './fontBase64'
import { Unit } from '@/types/product'

export type TProductWithAmounts = {
  [key: string]: {
    amount: number
    updatedAmount: number | null
    unit: Unit
  }
}

export type TTransition = {
  name: string
  amount: number
  updatedAmount: number | null
  unit: Unit
}

function getFirstDayOfMonth(year: number, month: number) {
  return new Date(year, month, 1)
}

const TimePeriodReport: FC = () => {
  const restaurantId = useRestaurantUrlId()
  const tableRef = React.useRef<HTMLTableElement>(null)

  const [startDate, setStartDate] = React.useState<Date>(
    getFirstDayOfMonth(new Date().getFullYear(), new Date().getMonth())
  )
  const [endDate, setEndDate] = React.useState<Date>(new Date())
  const { isLoading, reports } = useProductReceivedTransitionsReportsByPeriod(
    restaurantId,
    startDate.toISOString(),
    endDate.toISOString()
  )

  const sumData = useMemo(() => {
    const productsWithAmounts: TProductWithAmounts = {}
    reports?.forEach(r => {
      r.productTransitions.forEach(p => {
        if (productsWithAmounts[p.product.name]) {
          productsWithAmounts[p.product.name].amount += p.amount
          productsWithAmounts[p.product.name].updatedAmount =
            (productsWithAmounts[p.product.name].updatedAmount || 0) +
            (p.updatedAmount !== null
              ? p.amount - (p.amount - p.updatedAmount)
              : p.amount)
        } else {
          productsWithAmounts[p.product.name] = {
            amount: p.amount,
            updatedAmount:
              p.updatedAmount !== null
                ? p.amount - (p.amount - p.updatedAmount)
                : p.amount,
            unit: p.product.unit
          }
        }
      })
    })
    return Object.keys(productsWithAmounts).map((key: string) => ({
      name: key,
      amount: productsWithAmounts[key].amount,
      updatedAmount: productsWithAmounts[key].updatedAmount,
      unit: productsWithAmounts[key].unit
    })) as TTransition[]
  }, [reports])

  const handleGeneratePdfClick = () => {
    if (!tableRef.current) return
    const doc = new jsPDF()
    doc.addFileToVFS('OpenSans.ttf', fontBase64)
    doc.addFont('OpenSans.ttf', 'OpenSans', 'normal')
    doc.setFont('OpenSans', 'normal')
    doc.setFontSize(12)

    tableRef.current.querySelectorAll('td').forEach(element => {
      element.style.fontFamily = 'OpenSans'
    })

    doc.html(tableRef.current, {
      callback: function (doc) {
        doc.save('a4.pdf')
      },
      x: 15,
      y: 15,
      width: 170,
      windowWidth: 650
    })
  }

  return (
    <MainLayout>
      {isLoading ? (
        <SpinnerAligned align='center' />
      ) : (
        <Container>
          <Header>
            <Title>Период</Title>
            <Button onClick={handleGeneratePdfClick}>Скачать PDF</Button>
          </Header>
          <DatePickers>
            <DatePickerWrapper>
              <DatePicker
                dateFormat={'dd.MM.yyyy'}
                selected={startDate}
                onChange={date => setStartDate(date as Date)}
              />
            </DatePickerWrapper>
            <Divier />
            <DatePickerWrapper>
              <DatePicker
                dateFormat={'dd.MM.yyyy'}
                selected={endDate}
                onChange={date => setEndDate(date as Date)}
              />
            </DatePickerWrapper>
          </DatePickers>

          <ProductTransitionsTable transitions={sumData} elRef={tableRef} />
        </Container>
      )}
    </MainLayout>
  )
}

export default TimePeriodReport

import RestaurantService from '@/services/restaurant/restaurant.service'
import { useQuery } from '@tanstack/react-query'

const useRestaurantById = (id: string | null) => {
  const { isLoading, data } = useQuery(['restaurant by id'], async () => {
    return RestaurantService.getById(id)
  })

  return { isLoading, restaurant: data?.data }
}

export default useRestaurantById

import ProductService from '@/services/product/product.service'
import { useQuery } from '@tanstack/react-query'

const useProducts = () => {
  const { isLoading, data } = useQuery(['products'], async () => {
    return ProductService.getAll()
  })

  return { isLoading, products: data?.data }
}

export default useProducts

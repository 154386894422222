import React from 'react'
import Button from '@/components/Button'
import { useNavigate } from 'react-router'

import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import { IProductTransitionsReport } from '@/types/product.transitions.report'
import { format, isToday } from 'date-fns'
import { orderBy } from 'lodash'

import { Container, EmptyStateText, Table, TableBody } from './styles'
import useCreateProductTransitionsReport from '@/hooks/productTransitionsReports/useCreateProductTransitionsReport'
import { useAuth } from '@/hooks/useAuth'

interface TransitionReportsTableProps {
  reports: IProductTransitionsReport[]
  type: 'sender' | 'receiver'
}

const TransitionReportsTable: React.FC<TransitionReportsTableProps> = ({
  reports,
  type
}) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const restaurantId = useRestaurantUrlId()

  const createReport = useCreateProductTransitionsReport(
    restaurantId,
    ({ data }) => {
      const { id } = data
      navigate(`/restaurants/${restaurantId}/product-transitions/edit/${id}`)
    }
  )

  const handleRowClick = (report: IProductTransitionsReport) => {
    if (
      report.receiverRestaurant.id === parseInt(restaurantId || '0') &&
      user?.role === 'admin'
    ) {
      navigate(
        `/restaurants/${restaurantId}/product-transitions/approve/${report.id}`
      )
      return
    }

    if (
      report.senderRestaurant.id === parseInt(restaurantId || '0') &&
      isToday(new Date(report.date)) &&
      report.status !== 'approved'
    ) {
      navigate(
        `/restaurants/${restaurantId}/product-transitions/edit/${report.id}`
      )
    } else {
      navigate(`/restaurants/${restaurantId}/product-transitions/${report.id}`)
    }
  }

  return (
    <Container>
      {!reports?.length ? (
        <>
          <EmptyStateText>У вас пока нет ни одного перемещения</EmptyStateText>
        </>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Дата</th>
              <th>Статус</th>
              {type === 'receiver' && <th>Отправитель</th>}
              {type === 'sender' && <th>Получатель</th>}
            </tr>
          </thead>
          <TableBody>
            {orderBy(reports, ['date'], ['desc']).map(item => (
              <tr key={item.id} onClick={() => handleRowClick(item)}>
                <td>{format(new Date(item.date), 'dd.MM.yyy')}</td>
                <td>
                  {item.status === 'approved' ? 'Подтверждено' : 'Ожидает'}
                </td>
                {type === 'receiver' && <td>{item.senderRestaurant?.name}</td>}
                {type === 'sender' && <td>{item.receiverRestaurant?.name}</td>}
              </tr>
            ))}
          </TableBody>
        </Table>
      )}
      <Button onClick={() => createReport()} type='button'>
        Создать
      </Button>
    </Container>
  )
}

export default TransitionReportsTable

import Button from '@/components/Button'
import { FC, useState } from 'react'
import { Container, Content, Title } from './styles'
import MainLayout from '@/components/MainLayout'
import { useNavigate } from 'react-router'
import EditProductForm from '@/components/Forms/EditProductForm'
import { IProductDto } from '@/types/product'
import useCreateProduct from '@/hooks/products/useCreateProduct'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'

const NewProduct: FC = () => {
  const navigate = useNavigate()
  const restaurantId = useRestaurantUrlId()

  const [currentProduct, setCurrentProduct] = useState<IProductDto>({
    name: '',
    type: 'kitchen',
    price: 0,
    unit: 'weight',
    imagePath: ''
  })

  const createProduct = useCreateProduct(currentProduct, () =>
    navigate(`/restaurants/${restaurantId}/products`)
  )

  const handleButtonClick = async () => {
    if (!currentProduct.name) return
    createProduct()
  }

  return (
    <MainLayout>
      <Container>
        <Content>
          <Title>Добавить продукт</Title>
          <EditProductForm
            currentProduct={currentProduct}
            onChange={setCurrentProduct}
          />
          <Button onClick={handleButtonClick}>Добавить</Button>
        </Content>
      </Container>
    </MainLayout>
  )
}

export default NewProduct

import breakpoint from '@/shared/breakpoint'
import colors from '@/shared/colors'
import typography from '@/shared/typography'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;

  ${breakpoint.Mobile`
    padding: 0;
  `}
`

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${colors.White};
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 32px;

  ${breakpoint.Mobile`
    padding: 20px;
  `}
`

export const Title = styled.div`
  ${typography.H6};
  margin-bottom: 16px;
`

export const Inputs = styled.div`
  margin-bottom: 16px;
`

export const ProductTransitionsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const ProductTransitionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  border-bottom: 1px solid ${colors.Gray200};

  &:first-child {
    border-top: 1px solid ${colors.Gray200};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const ButtonWrapper = styled.div`
  align-self: center;
  margin-bottom: 16px;
`

export const InputWrapper = styled.div`
  max-width: 128px;
  margin-left: 24px;
`

export const ProductName = styled.div`
  width: 100%;
  max-width: 220px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 24px;
  flex: 2;

  ${breakpoint.Mobile`
    max-width: 100px;
    margin-right: 16px;
  `}
`

export const ProductAmount = styled.div`
  width: 100%;
  max-width: 220px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 24px;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }
`

export const ProductTransitionsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const ProductTransitionsHeaderItem = styled.div`
  width: 100%;
  max-width: 128px;
  ${typography.Caption};
  font-weight: 600;
  margin-right: 24px;

  &:first-child {
    max-width: 220px;
    flex: 2;
  }

  ${breakpoint.Mobile`
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    margin-right: 16px;
  `}
`

import styled from 'styled-components'
import Colors from '@/shared/colors'
import Typography from '@/shared/typography'

export const Table = styled.table`
  width: 100%;
  color: ${Colors.Black100};
  margin-bottom: 24px;

  thead {
    background: ${Colors.Gray100};
    border: 1px solid ${Colors.Gray150};
  }

  th {
    text-align: left;
    user-select: none;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.Black100};
    font-weight: 500;

    &:last-child {
      text-align: right;
    }
  }

  tbody {
    border: none;
    background-color: ${Colors.White};

    tr {
      border-bottom: 1px solid ${Colors.Gray150};

      &:hover {
        cursor: pointer;
        background-color: rgba(${Colors.Gray50}, 0.8);
      }
    }
  }

  td {
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.Black100};
    font-weight: 400;
    min-height: 44px;

    &:last-child {
      text-align: right;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const EmptyStateText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Gray400};
  text-align: center;
  margin-bottom: 24px;
`

export const TableBody = styled.tbody``

export const DeleteButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Blue300};
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${Colors.Blue400};
  }
`

import { FC } from 'react'
import { Inputs } from './styles'
import Select from '@/components/Select'
import Input from '@/components/Input'
import {
  IProductDto,
  ProductType,
  ProductTypeOption,
  ProductTypeValue,
  Unit,
  UnitOption,
  Value
} from '@/types/product'

interface EditProductFormProps {
  currentProduct: IProductDto
  onChange: (product: IProductDto) => void
}

const unitNames: Record<Unit, Value> = {
  weight: 'кг',
  volume: 'л',
  count: 'шт'
}

const productTypes: Record<ProductType, ProductTypeValue> = {
  kitchen: 'Кухня',
  household: 'Хозы'
}

const EditProductForm: FC<EditProductFormProps> = ({
  currentProduct,
  onChange
}) => {
  const unitOptions: UnitOption[] = Object.entries(unitNames).map(
    ([key, value]) => ({
      value: key as Unit,
      label: value
    })
  )

  const typeOptions: ProductTypeOption[] = Object.entries(productTypes).map(
    ([key, value]) => ({
      value: key as ProductType,
      label: value
    })
  )

  return (
    <>
      <Inputs>
        <Input
          name='name'
          label='Название'
          value={currentProduct.name}
          onChange={e =>
            onChange({
              ...currentProduct,
              [e.target.name]: e.target.value
            })
          }
        />
        <Select
          name='unit'
          label='Ед. измерения'
          options={unitOptions}
          value={
            unitOptions?.find(unit => unit.value === currentProduct.unit) ||
            unitOptions[0]
          }
          onChange={option =>
            onChange({
              ...currentProduct,
              unit: option?.value as Unit
            })
          }
        />
        <Select
          name='type'
          label='Тип продукта'
          options={typeOptions}
          value={
            typeOptions?.find(type => type.value === currentProduct.type) ||
            typeOptions[0]
          }
          onChange={option =>
            onChange({
              ...currentProduct,
              type: option?.value as ProductType
            })
          }
        />
        <Input
          label='Цена'
          name='price'
          value={currentProduct.price.toString()}
          onChange={e =>
            onChange({
              ...currentProduct,
              [e.target.name]: parseFloat(e.target.value || '0')
            })
          }
        />
      </Inputs>
    </>
  )
}

export default EditProductForm

import RestaurantService from '@/services/restaurant/restaurant.service'
import { IRestaurantDto } from '@/types/restaurant'
import { useMutation } from '@tanstack/react-query'

const useCreateRestaurant = (
  restaurant: IRestaurantDto,
  onSuccess: () => void
) => {
  const { mutate } = useMutation(
    ['create restaurant'],
    () => RestaurantService.create(restaurant),
    {
      onSuccess
    }
  )

  return mutate
}

export default useCreateRestaurant

import { css, FlattenSimpleInterpolation } from 'styled-components'

const breakpoint = {
  MobileSmall: (
    style: TemplateStringsArray | string
  ): FlattenSimpleInterpolation =>
    css`
      @media only screen and (max-width: 390px) {
        ${css`
          ${style}
        `}
      }
    `,
  Mobile: (style: TemplateStringsArray | string): FlattenSimpleInterpolation =>
    css`
      @media only screen and (max-width: 600px) {
        ${css`
          ${style}
        `}
      }
    `,
  Tablet: (style: TemplateStringsArray | string): FlattenSimpleInterpolation =>
    css`
      @media only screen and (max-width: 768px) {
        ${css`
          ${style}
        `}
      }
    `
}

export default breakpoint

import instance from '../../api/api.interceptor'
import { IProduct, IProductDto } from '../../types/product'

const ProductService = {
  async getAll() {
    return instance<IProduct[]>({
      url: `/products`,
      method: 'GET'
    })
  },

  async getById(id: string | null) {
    if (!id) return null
    return instance<IProduct>({
      url: `/products/${id}`,
      method: 'GET'
    })
  },

  async create(product: IProductDto) {
    return instance<IProduct>({
      url: `/products`,
      method: 'POST',
      data: product
    })
  },

  async update(id: string | null, product: IProductDto) {
    if (!id) return null
    return instance<IProduct>({
      url: `/products/${id}`,
      method: 'PUT',
      data: product
    })
  },

  async delete(id: string) {
    return instance<IProduct>({
      url: `/products/${id}`,
      method: 'DELETE'
    })
  }
}

export default ProductService

import ProductService from '@/services/product/product.service'
import { IProductDto } from '@/types/product'
import { useMutation } from '@tanstack/react-query'

const useUpdateProduct = (
  id: string | null,
  product: IProductDto,
  onSuccess?: () => void
) => {
  const { mutate } = useMutation(
    ['update product'],
    () => ProductService.update(id, product),
    {
      onSuccess
    }
  )

  return mutate
}

export default useUpdateProduct

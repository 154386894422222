/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { IUser } from '@/types/user'

interface UserContextProps {
  user: IUser | null
  setUser: (user: IUser | null) => void
}

export const UserContext = React.createContext<UserContextProps>({
  user: null,
  setUser: () => {}
})

interface UserContextProviderProps {
  children: React.ReactNode
}

export const UserContextProvider: FC<UserContextProviderProps> = ({
  children
}) => {
  const [user, setUser] = useLocalStorage<IUser | null>('user', null)
  return (
    <UserContext.Provider
      value={{
        user,
        setUser
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

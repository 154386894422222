import Button from '@/components/Button'
import { IRestaurantDto } from '@/types/restaurant'
import { FC, useEffect, useState } from 'react'
import { Container, Content, Title } from './styles'
import useRestaurantById from '@/hooks/restaurants/useRestaurantById'
import useUpdateRestaurant from '@/hooks/restaurants/useUpdateRestaurant'
import EditRestaurantForm from '@/components/Forms/EditRestaurantForm'
import { useNavigate } from 'react-router'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'

const EditRestaurant: FC = () => {
  const id = useRestaurantUrlId()
  const navigate = useNavigate()
  const [currentRestaurant, setCurrentRestaurant] = useState<IRestaurantDto>({
    name: '',
    address: '',
    rentPrice: 0,
    communalPayments: 0,
    consumablesPrice: 0,
    mobileConnectionPrice: 0,
    internetPrice: 0,
    iikoPrice: 0,
    smartomatoPrice: 0,
    bankFee: 0,
    bankOnlineFee: 0
  })
  const { restaurant, isLoading } = useRestaurantById(id)

  const updateRestaurant = useUpdateRestaurant(id, currentRestaurant, () =>
    navigate('/restaurants')
  )

  useEffect(() => {
    if (!isLoading && restaurant) {
      setCurrentRestaurant(restaurant)
    }
  }, [restaurant, isLoading])

  const handleButtonClick = async () => {
    if (!currentRestaurant.name || !currentRestaurant.address) return
    updateRestaurant()
  }

  return (
    <Container>
      <Content>
        <Title>Создание нового ресторана</Title>
        <EditRestaurantForm
          currentRestaurant={currentRestaurant}
          onChange={setCurrentRestaurant}
        />
        <Button onClick={handleButtonClick}>Обновить</Button>
      </Content>
    </Container>
  )
}

export default EditRestaurant

import colors from '@/shared/colors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const DatePickerWrapper = styled.div``

export const DatePickers = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const Divier = styled.div`
  width: 10px;
  height: 2px;
  background-color: ${colors.Black300};
  margin: 0 8px;
`

export const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.Black100};
  font-weight: 600;
  margin-bottom: 8px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

import React from 'react'

import {
  Container,
  Sidebar,
  SidebarHeader,
  CompanyContainer,
  CompanyName,
  ExitButton,
  MainContainer,
  SidebarGroup,
  GroupTitle,
  GroupItem,
  ItemTitle,
  Topbar,
  TopbarTitle,
  MainContentWrapper,
  MainContent,
  BackIconwrapper,
  TopbarLeft,
  TopbarRight,
  IconWrapper
} from './styles'
import { useLocation, useNavigate } from 'react-router'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import { navigation } from './navigation'
import { useAuth } from '@/hooks/useAuth'
import { Icon24 } from '../Icon'
import useRestaurants from '@/hooks/restaurants/useRestaurants'

type MainLayoutProps = {
  children: React.ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const restaurantId = useRestaurantUrlId()
  const { restaurants } = useRestaurants()
  const { user, logout } = useAuth()
  const navigationItems = navigation(restaurantId, user?.role || 'operator')
  const isActive = (item: string) => item === location.pathname

  const currentPageTitle = () => {
    const path = location.pathname
    if (path.includes('/users')) return 'Сотрудники'
    if (path.includes('/products')) return 'Продукты'
    if (path.includes('/product-transitions')) return 'Перемещения'
    if (path.includes(`/restaurants/${restaurantId}`)) return 'Главная'
    if (path.includes('/restaurants')) return 'Рестораны'
  }

  return (
    <Container>
      <Sidebar>
        <SidebarHeader>
          <CompanyContainer>
            <CompanyName>
              {restaurants && restaurantId
                ? restaurants.find(rest => rest.id === parseInt(restaurantId))
                    ?.name
                : 'KingKong'}
            </CompanyName>
          </CompanyContainer>

          <ExitButton onClick={logout} />
        </SidebarHeader>

        <SidebarGroup>
          <GroupTitle>Основные</GroupTitle>
          {navigationItems &&
            navigationItems.map((item, index) => (
              <GroupItem
                key={item.url}
                onClick={() => navigate(item.url)}
                isActive={isActive(item.url)}
              >
                <IconWrapper>
                  <item.icon />
                </IconWrapper>
                <ItemTitle>{item.name}</ItemTitle>
              </GroupItem>
            ))}
        </SidebarGroup>
      </Sidebar>

      <MainContainer>
        <Topbar>
          <TopbarLeft>
            {currentPageTitle() !== 'Главная' && (
              <BackIconwrapper onClick={() => navigate(-1)}>
                <Icon24.ArrowLeft />
              </BackIconwrapper>
            )}
            <TopbarTitle>{currentPageTitle()}</TopbarTitle>
          </TopbarLeft>
          <TopbarRight>
            <ExitButton onClick={logout} />
          </TopbarRight>
        </Topbar>
        <MainContentWrapper>
          <MainContent>{children}</MainContent>
        </MainContentWrapper>
      </MainContainer>
    </Container>
  )
}

export default MainLayout

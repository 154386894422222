import Cookies from 'js-cookie'

import { IAuthResponse, ITokens } from '@/types/user'

export const getAccesToken = () => Cookies.get('accessToken') || null
export const getRefreshToken = () => Cookies.get('refreshToken') || null

export const getUserFromStorage = () => {
  return JSON.parse(localStorage.getItem('user') || '{}')
}

export const saveTokens = (data: ITokens) => {
  Cookies.set('accessToken', data.accessToken)
  Cookies.set('refreshToken', data.refreshToken)
}

export const removeFromStorage = () => {
  Cookies.remove('accessToken')
  Cookies.remove('refreshToken')
  localStorage.removeItem('user')
}

export const saveToStorage = (data: IAuthResponse) => {
  saveTokens(data)
  localStorage.setItem('user', JSON.stringify(data.user))
}

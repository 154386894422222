import styled from 'styled-components'
import Colors from '@/shared/colors'
import Typography from '@/shared/typography'

import logoSrc from '@/assets/images/sidebar/kk_logo.svg'
import exitSrc from '@/assets/images/sidebar/exit.svg'
import statisticsSrc from '@/assets/images/sidebar/statistics.svg'
import tipsSrc from '@/assets/images/sidebar/tips.svg'
import clientsSrc from '@/assets/images/sidebar/clients.svg'
import breakpoint from '@/shared/breakpoint'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  ${breakpoint.Mobile`
    height: calc(100vh - 64px);
  `}
`

export const Sidebar = styled.div`
  width: 272px;
  height: 100%;
  background-color: ${Colors.White};
  flex-shrink: 0;
  padding: 20px 0;
  border-right: 1px solid ${Colors.Gray200};

  ${breakpoint.Mobile`
    display: none;
  `}
`

export const SidebarHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 20px;
`

export const CompanyContainer = styled.div`
  padding-left: 32px;
`

export const CompanyName = styled.div`
  position: relative;
  ${Typography.Subhead};

  &:before {
    content: '';
    position: absolute;
    left: -32px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    background-image: url(${logoSrc});
  }
`

export const Plan = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Gray200};
`

export const ExitButton = styled.div`
  width: 24px;
  height: 24px;
  margin-top: 4px;
  background-image: url(${exitSrc});
  cursor: pointer;

  ${breakpoint.Mobile`
    justify-self: flex-end;
  `}
`

export const SidebarGroup = styled.div`
  margin-bottom: 16px;
`

export const GroupTitle = styled.div`
  color: ${Colors.Gray500};
  padding-left: 20px;
  margin-bottom: 8px;
`

export const GroupItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding-left: 20px;
  background-color: ${({ isActive }) =>
    isActive ? Colors.Gray100 : Colors.White};

  &:hover {
    background-color: ${Colors.Gray150};
  }
`

export const ItemIconStatistics = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${statisticsSrc});
  margin-right: 16px;
`

export const ItemIconTips = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${tipsSrc});
  margin-right: 16px;
`

export const ItemIconClients = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${clientsSrc});
  margin-right: 16px;
`

export const ItemTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Gray700};
  font-weight: 600;
`

export const MainContainer = styled.div`
  width: 100%;
`

export const Topbar = styled.div`
  width: 100%;
  height: 72px;
  background-color: ${Colors.White};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid ${Colors.Gray200};

  ${breakpoint.Mobile`
    height: 64px;
  `}
`

export const TopbarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const TopbarRight = styled.div`
  display: none;

  ${breakpoint.Mobile`
    display: block;
  `}
`

export const TopbarTitle = styled.div`
  ${Typography.Subhead};
  font-weight: 600;
  color: ${Colors.Gray700};
`

export const MainContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.Gray50};
  padding: 24px 32px 128px;
  overflow-y: auto;

  ${breakpoint.Mobile`
    padding: 20px 20px 64px;
  `}
`

export const MainContent = styled.div`
  max-width: 1032px;
  margin: 0 auto;
`

export const BackIconwrapper = styled.div`
  display: none;
  margin-right: 16px;
  cursor: pointer;

  ${breakpoint.Mobile`
    display: block;
  `}
`

export const IconWrapper = styled.div`
  display: none;
`

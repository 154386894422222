import ProductTransitionsReportService from '@/services/productTransitionsReport/product.transition.service'
import { useQuery } from '@tanstack/react-query'

const useProductReceivedTransitionsReports = (restaurantId: string | null) => {
  const { isLoading, data } = useQuery(
    ['received product transitions'],
    async () => {
      return ProductTransitionsReportService.getAllReceived(restaurantId)
    }
  )

  return { isLoading, productTransitions: data?.data }
}

export const useProductReceivedTransitionsReportsByPeriod = (
  restaurantId: string | null,
  startDate: string,
  endDate: string
) => {
  const { isLoading, data } = useQuery({
    queryKey: ['received product transitions by period', startDate, endDate],
    queryFn: () => {
      return ProductTransitionsReportService.getAllReceivedByPeriod(
        restaurantId,
        startDate,
        endDate
      )
    }
  })

  // const { isLoading, data } = useQuery(
  //   ['received product transitions by period'],
  //   async () => {
  //     return ProductTransitionsReportService.getAllReceivedByPeriod(
  //       restaurantId,
  //       startDate,
  //       endDate
  //     )
  //   }
  // )

  return { isLoading, reports: data?.data }
}

export default useProductReceivedTransitionsReports

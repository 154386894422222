import React, { useEffect } from 'react'

import MainLayout from '@/components/MainLayout'
import useProductReceivedTransitionsReports from '@/hooks/productTransitionsReports/useProductReceivedTransitionsReports'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import useProductSentTransitionsReports from '@/hooks/productTransitionsReports/useProductSentTransitionsReports'
import TransitionReportsTable from '@/components/Tables/TransitionReportsTable'
import RadioButtons from '@/components/RadioButtons/RadioButtons'

import {
  Container,
  ButtonsWrapper,
  PendingReports,
  PendingReport,
  PendingReportBottom,
  PendingReportCount,
  PendingReportStatus,
  PendingReportTitle,
  Title,
  PendingReportDelete
} from './styles'
import { IProductTransitionsReport } from '@/types/product.transitions.report'
import { format } from 'date-fns'
import { useNavigate } from 'react-router'
import { Icon24 } from '@/components/Icon'
import useDeleteProductTransitionsReport from '@/hooks/productTransitionsReports/useDeleteProductTransitionsReport'
import { useAuth } from '@/hooks/useAuth'
import { SpinnerAligned } from '@/components/Spinner/Spinner'

const ProductTransitions: React.FC = () => {
  const restaurantId = useRestaurantUrlId()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { isLoading: receivedLoading, productTransitions: receivedReports } =
    useProductReceivedTransitionsReports(restaurantId)
  const {
    isLoading: sentLoading,
    productTransitions: sentReports,
    refetch: refetchSentReports
  } = useProductSentTransitionsReports(restaurantId)
  const [currentReceivedReports, setCurrentReceivedReports] = React.useState<
    IProductTransitionsReport[]
  >([])
  const [currentSentReports, setCurrentSentReports] = React.useState<
    IProductTransitionsReport[]
  >([])
  const [pendingReports, setPendingReports] = React.useState<
    IProductTransitionsReport[]
  >([])
  const [editingReports, setEditingReports] = React.useState<
    IProductTransitionsReport[]
  >([])

  const deleteReport = useDeleteProductTransitionsReport(() =>
    refetchSentReports()
  )
  const [currentType, setCurrentType] = React.useState<'receiver' | 'sender'>(
    'receiver'
  )

  useEffect(() => {
    if (!receivedLoading && !sentLoading && receivedReports && sentReports) {
      setCurrentReceivedReports(
        receivedReports.filter(report => report.status === 'approved')
      )
      setCurrentSentReports(
        sentReports.filter(report => report.status !== 'editing')
      )
      setPendingReports(
        receivedReports.filter(report => report.status === 'pending')
      )
      setEditingReports(
        sentReports.filter(report => report.status === 'editing')
      )
    }
  }, [receivedLoading, sentLoading, receivedReports, sentReports])

  const buttonOptions = [
    {
      value: 'receiver',
      label: 'Полученные'
    },
    {
      value: 'sender',
      label: 'Отправленные'
    }
  ]

  return (
    <MainLayout>
      {receivedLoading || sentLoading ? (
        <SpinnerAligned align='center' />
      ) : (
        <Container>
          {!!editingReports.length && <Title>Ждут отправки</Title>}
          {!!editingReports.length && (
            <PendingReports>
              {editingReports.map(report => (
                <PendingReport
                  key={report.id}
                  onClick={() => navigate(`edit/${report.id}`)}
                >
                  {user?.role === 'admin' && (
                    <PendingReportDelete
                      onClick={e => {
                        e.stopPropagation()
                        deleteReport({ id: report.id.toString() })
                      }}
                    >
                      <Icon24.Trash />
                    </PendingReportDelete>
                  )}

                  <PendingReportTitle>
                    {format(new Date(report.date), 'dd.MM.yyyy')}
                  </PendingReportTitle>
                  <PendingReportBottom>
                    <PendingReportCount>
                      Позиций: {report.productTransitions.length}
                    </PendingReportCount>
                    <PendingReportStatus>Редактируется</PendingReportStatus>
                  </PendingReportBottom>
                </PendingReport>
              ))}
            </PendingReports>
          )}
          {!!pendingReports.length && <Title>Ожидают подтверждения</Title>}
          <PendingReports>
            {pendingReports.map(report => (
              <PendingReport
                key={report.id}
                onClick={() => navigate(`approve/${report.id}`)}
              >
                <PendingReportTitle>
                  {format(new Date(report.date), 'dd.MM.yyyy')}
                </PendingReportTitle>
                <PendingReportBottom>
                  <PendingReportCount>
                    Позиций: {report.productTransitions.length}
                  </PendingReportCount>
                  <PendingReportStatus>Ожидает</PendingReportStatus>
                </PendingReportBottom>
              </PendingReport>
            ))}
          </PendingReports>
          <ButtonsWrapper>
            <RadioButtons
              options={buttonOptions}
              onChange={option =>
                setCurrentType(option.value as 'receiver' | 'sender')
              }
            />
          </ButtonsWrapper>
          <TransitionReportsTable
            type={currentType}
            reports={
              (currentType === 'receiver'
                ? currentReceivedReports
                : currentSentReports) || []
            }
          />
        </Container>
      )}
    </MainLayout>
  )
}

export default ProductTransitions

import colors from '@/shared/colors'
import typography from '@/shared/typography'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.Gray100};
  padding: 32px;
`

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${colors.White};
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 32px;
`

export const Title = styled.div`
  ${typography.H6};
  margin-bottom: 16px;
`

export const Inputs = styled.div`
  margin-bottom: 16px;
`

import ProductTransitionsReportService from '@/services/productTransitionsReport/product.transition.service'
import { useQuery } from '@tanstack/react-query'

const useProductSentTransitionsReports = (restaurantId: string | null) => {
  const { isLoading, data, refetch } = useQuery(
    ['sent product transitions'],
    async () => {
      return ProductTransitionsReportService.getAllSent(restaurantId)
    }
  )

  return { isLoading, productTransitions: data?.data, refetch }
}

export default useProductSentTransitionsReports

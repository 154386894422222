import React from 'react'
import { orderBy } from 'lodash'

import { Container, EmptyStateText, Table, TableBody } from './styles'
import { TTransition } from '@/views/ProductTransition/TimePeriodReport/TimePeriodReport'
import { IProduct, Unit } from '@/types/product'

interface ProductTransitionsTableProps {
  transitions: TTransition[]
  elRef?: React.Ref<HTMLTableElement>
}

const measurementSystem = (unit: Unit) => {
  switch (unit) {
    case 'weight':
      return 'кг'
    case 'volume':
      return 'л'
    case 'count':
      return 'шт'
    default:
      return 'кг'
  }
}

const ProductTransitionsTable: React.FC<ProductTransitionsTableProps> = ({
  transitions,
  elRef
}) => {
  return (
    <Container>
      {!transitions?.length ? (
        <>
          <EmptyStateText>
            Перемещения в данном интервале не найдены
          </EmptyStateText>
        </>
      ) : (
        <Table ref={elRef}>
          <thead>
            <tr>
              <th>Продукт</th>
              <th>Количество</th>
              <th>Подтверждённое количество</th>
            </tr>
          </thead>
          <TableBody>
            {orderBy(transitions, ['name'], ['asc']).map(item => (
              <tr key={item.name}>
                <td>{item.name}</td>
                <td>
                  {item.amount} {measurementSystem(item.unit)}
                </td>
                <td>
                  {item.updatedAmount} {measurementSystem(item.unit)}
                </td>
              </tr>
            ))}
          </TableBody>
        </Table>
      )}
    </Container>
  )
}

export default ProductTransitionsTable

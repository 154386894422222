import { useAuth } from '@/hooks/useAuth'
import { getAccesToken, getRefreshToken } from '@/services/auth/auth.helper'
import { FC, PropsWithChildren, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

const AuthProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, logout, checkAuth } = useAuth()

  useEffect(() => {
    const accessToken = getAccesToken()
    if (accessToken) checkAuth()
  }, [])

  useEffect(() => {
    const refreshToken = getRefreshToken()
    if (!refreshToken && user) {
      logout()
    }
  }, [location, user])

  if (user && location.pathname === '/login') navigate('/restaurants')
  if (!user && location.pathname !== '/login')
    navigate('/login', { replace: true })
  return <>{children}</>
}

export default AuthProvider

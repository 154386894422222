import ProductService from '@/services/product/product.service'
import { useQuery } from '@tanstack/react-query'

const useProductById = (id: string | null) => {
  const { isLoading, data } = useQuery(['product by id'], async () => {
    return ProductService.getById(id)
  })

  return { isLoading, product: data?.data }
}

export default useProductById

import UserService from '@/services/user/user.service'
import { IUserDto } from '@/types/user'
import { useMutation } from '@tanstack/react-query'

const useUpdateUser = (
  id: string | null,
  user: IUserDto,
  onSuccess?: () => void
) => {
  const { mutate } = useMutation(
    ['update user'],
    () => UserService.update(id, user),
    {
      onSuccess
    }
  )

  return mutate
}

export default useUpdateUser

import instance from '../../api/api.interceptor'
import { IRestaurant, IRestaurantDto } from '../../types/restaurant'

const RestaurantService = {
  async getAll() {
    return instance<IRestaurant[]>({
      url: `/restaurants`,
      method: 'GET'
    })
  },

  async getById(id: string | null) {
    if (!id) return null
    return instance<IRestaurant>({
      url: `/restaurants/${id}`,
      method: 'GET'
    })
  },

  async create(restaurant: IRestaurantDto) {
    return instance<IRestaurant>({
      url: `/restaurants`,
      method: 'POST',
      data: restaurant
    })
  },

  async update(id: string | null, restaurant: IRestaurantDto) {
    if (!id) return null
    return instance<IRestaurant>({
      url: `/restaurants/${id}`,
      method: 'PUT',
      data: restaurant
    })
  },

  async delete(id: string) {
    return instance<IRestaurant>({
      url: `/restaurants/${id}`,
      method: 'DELETE'
    })
  }
}

export default RestaurantService

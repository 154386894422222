import { useState } from 'react'
import { useAuth } from '@/hooks/useAuth'

import Input from '@/components/Input'
import Button from '@/components/Button'

import { Container, Content, InputWrapper, Title } from './styles'

export default function Login() {
  const { login, isLoading } = useAuth()
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: ''
  })

  const handleLogin = async () => {
    if (!loginForm.email || !loginForm.password) return
    login(loginForm)
  }

  return (
    <Container>
      <Content>
        <Title>Вход</Title>
        <InputWrapper>
          <Input
            label='Email'
            name='email'
            value={loginForm.email}
            onChange={e =>
              setLoginForm({ ...loginForm, [e.target.name]: e.target.value })
            }
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label='Пароль'
            name='password'
            type='password'
            value={loginForm.password}
            onChange={e =>
              setLoginForm({ ...loginForm, [e.target.name]: e.target.value })
            }
          />
        </InputWrapper>

        <Button onClick={handleLogin}>
          {isLoading ? 'Загрузка...' : 'Войти'}
        </Button>
      </Content>
    </Container>
  )
}

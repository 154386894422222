import { useAuth } from '@/hooks/useAuth'
import { FC, PropsWithChildren } from 'react'
import { useLocation, useNavigate } from 'react-router'

const CheckRole: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()

  if (
    location.pathname === '/restaurants' &&
    user?.role !== 'admin' &&
    user?.role !== 'chef'
  ) {
    navigate(`restaurants/${user?.restaurantId}`, { replace: true })
  }

  return <>{children}</>
}

export default CheckRole

import Button from '@/components/Button'
import { FC, useEffect, useMemo, useState } from 'react'
import MainLayout from '@/components/MainLayout'
import { useNavigate, useParams } from 'react-router'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import { IProductTransitionsReportForm } from '@/types/product.transitions.report'
import EditProductTransitionsReportForm from '@/components/Forms/EditProductTransitionsReportForm'
import useProducts from '@/hooks/products/useProducts'
import Select from '@/components/Select'
import Input from '@/components/Input'

import {
  Container,
  Content,
  Title,
  ProductTransitionsList,
  ProductTransitionItem,
  ButtonWrapper,
  InputWrapper,
  SaveButtonsWrapper,
  IconWrapper
} from './styles'
import { Icon24 } from '@/components/Icon'
import useProductTransitionsReportById from '@/hooks/productTransitionsReports/useProductTransitionsReportById'
import useUpdateProductTransitionsReport from '@/hooks/productTransitionsReports/useUpdateProductTransitionsReport'
import useRestaurants from '@/hooks/restaurants/useRestaurants'
import {
  IProductTransitionDto,
  IProductTransitionForm
} from '@/types/product.transition'

const EditProductTransition: FC = () => {
  let { reportId } = useParams<{ reportId: string }>() || null
  const navigate = useNavigate()
  const { products } = useProducts()
  const restaurantId = useRestaurantUrlId()
  const { restaurants } = useRestaurants()
  const [currentReport, setCurrentReport] =
    useState<IProductTransitionsReportForm>({
      date: new Date().toISOString(),
      productTransitions: [],
      status: 'editing',
      senderRestaurantId: parseInt(restaurantId || '0'),
      receiverRestaurantId: parseInt(restaurantId || '0') + 1
    })

  const productOptions = useMemo(
    () =>
      products?.map(product => ({
        value: product.id.toString(),
        label: product.name
      })),
    [products]
  )
  const { report, isLoading } = useProductTransitionsReportById(
    reportId || null
  )

  const updateReport = useUpdateProductTransitionsReport(() =>
    navigate(`/restaurants/${restaurantId}/product-transitions`)
  )

  const sendReport = useUpdateProductTransitionsReport(() =>
    navigate(`/restaurants/${restaurantId}/product-transitions`)
  )

  useEffect(() => {
    if (!isLoading && report && restaurants) {
      setCurrentReport({
        date: report.date,
        status: report.status,
        senderRestaurantId: parseInt(restaurantId || '0'),
        receiverRestaurantId:
          report.receiverRestaurant?.id ||
          restaurants.find(
            restaurant => restaurant.id !== parseInt(restaurantId || '0')
          )?.id ||
          0,
        productTransitions: report.productTransitions.map(item => ({
          id: item.id,
          amount: item.amount.toString(),
          updatedAmount: item.updatedAmount?.toString() || '',
          productId: item.product.id
        }))
      })
    }
  }, [report, restaurants, isLoading, restaurantId])

  const handleSaveClick = async () => {
    if (!currentReport.productTransitions.length) return
    updateReport({
      id: reportId || null,
      report: {
        ...currentReport,
        productTransitions: transformProductTransitions(
          currentReport.productTransitions
        )
      }
    })
  }

  const handleSendClick = async () => {
    if (!currentReport.productTransitions.length) return
    sendReport({
      id: reportId || null,
      report: {
        ...currentReport,
        productTransitions: transformProductTransitions(
          currentReport.productTransitions
        ),
        status: 'pending'
      }
    })
  }

  const handleDeleteTransition = (productId: number) => {
    setCurrentReport(report => ({
      ...report,
      productTransitions: report.productTransitions.filter(
        item => item.productId !== productId
      )
    }))
  }

  const productTypeUnit = (type: 'weight' | 'volume' | 'count') =>
    ({
      weight: 'кг',
      volume: 'л',
      count: 'шт'
    }[type])

  const transformProductTransitions = (
    transitions: IProductTransitionForm[]
  ): IProductTransitionDto[] => {
    return transitions
      .map(item => ({
        ...item,
        amount: parseFloat(item.amount || '0'),
        updatedAmount: parseFloat(item.updatedAmount || '0') || null
      }))
      .filter(item => item.amount !== 0)
  }

  if (!products) return null

  return (
    <MainLayout>
      <Container>
        <Content>
          <Title>Создание перемещения</Title>
          <EditProductTransitionsReportForm
            currentProductTransitionReport={currentReport}
            onChange={setCurrentReport}
          />
          <ProductTransitionsList>
            {currentReport.productTransitions.map(productTransition => (
              <ProductTransitionItem key={productTransition.productId}>
                {productOptions && (
                  <Select
                    name='product'
                    label='Продукт'
                    options={productOptions || []}
                    value={
                      productOptions?.find(
                        option =>
                          option.value ===
                          productTransition.productId?.toString()
                      ) || productOptions[0]
                    }
                    onChange={option => {
                      if (
                        currentReport.productTransitions
                          .map(item => item.productId)
                          .indexOf(parseInt(option?.value || '0')) !== -1
                      )
                        return
                      const newProductTransitions =
                        currentReport.productTransitions.map(item => {
                          if (!option) return item
                          if (item.productId === productTransition.productId) {
                            return {
                              ...item,
                              productId: parseInt(option.value)
                            }
                          }
                          return item
                        })

                      setCurrentReport(report => ({
                        ...report,
                        productTransitions: newProductTransitions
                      }))
                    }}
                  />
                )}
                <InputWrapper>
                  <Input
                    name='amount'
                    label={productTypeUnit(
                      products.find(
                        product => product.id === productTransition.productId
                      )?.unit || 'weight'
                    )}
                    value={
                      productTransition.amount
                        ? productTransition.amount.toString()
                        : ''
                    }
                    onChange={e => {
                      const newProductTransitions =
                        currentReport.productTransitions.map(item => {
                          if (item.productId === productTransition.productId) {
                            return {
                              ...item,
                              amount: e.target.value.replace(',', '.')
                            }
                          }
                          return item
                        })

                      setCurrentReport(report => ({
                        ...report,
                        productTransitions: newProductTransitions
                      }))
                    }}
                  />
                </InputWrapper>
                <IconWrapper
                  onClick={() =>
                    handleDeleteTransition(productTransition.productId)
                  }
                >
                  <Icon24.Trash />
                </IconWrapper>
              </ProductTransitionItem>
            ))}
            <ButtonWrapper>
              <Button
                type='ghost'
                onClick={() =>
                  setCurrentReport(report => {
                    return {
                      ...report,
                      productTransitions: report.productTransitions.concat({
                        productId:
                          products.find(
                            product =>
                              report.productTransitions
                                .map(item => item.productId)
                                .indexOf(product.id) === -1
                          )?.id || products[0].id,
                        amount: '',
                        updatedAmount: ''
                      })
                    }
                  })
                }
              >
                <Icon24.Plus />
              </Button>
            </ButtonWrapper>
          </ProductTransitionsList>
          <SaveButtonsWrapper>
            <Button onClick={handleSaveClick}>Сохранить</Button>
            {!!currentReport.productTransitions.length &&
              currentReport.productTransitions.every(
                transition => !!transition.id
              ) && (
                <Button color='green' onClick={handleSendClick}>
                  Отправить
                </Button>
              )}
          </SaveButtonsWrapper>
        </Content>
      </Container>
    </MainLayout>
  )
}

export default EditProductTransition

import { useContext, useState } from 'react'

import { UserContext } from '@/context/user.context'
import { removeFromStorage } from '@/services/auth/auth.helper'
import AuthService from '@/services/auth/auth.service'
import { IEmailPassword, IUserDto } from '@/types/user'
import { errorCatch } from '@/api/api.helper'
import { useNavigate } from 'react-router'

export const useAuth = () => {
  const { user, setUser } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const login = async (data: IEmailPassword) => {
    try {
      setIsLoading(true)
      const res = await AuthService.login(data)
      setUser(res.user)
      setIsLoading(false)
      navigate('/restaurants', { replace: true })
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const register = async (data: IUserDto) => {
    try {
      setIsLoading(true)
      await AuthService.register(data)
      // setUser(res.user)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const logout = () => {
    removeFromStorage()
    setUser(null)
    navigate('/login')
  }

  const checkAuth = async () => {
    try {
      const response = await AuthService.getNewTokens()
      return response.data
    } catch (error) {
      if (errorCatch(error) === 'jwt expired') {
        logout()
      }
      return null
    }
  }

  return { user, login, register, logout, checkAuth, isLoading }
}

import React from 'react'
import Button from '@/components/Button'
import { useNavigate } from 'react-router'

import {
  Container,
  EmptyStateText,
  Table,
  TableBody
  // DeleteButton
} from './styles'

import { IUser } from '@/types/user'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'

interface UsersTableProps {
  users: IUser[]
}

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const restaurantId = useRestaurantUrlId()
  const navigate = useNavigate()
  const showEmptyState = users.length === 0

  return (
    <Container>
      {showEmptyState ? (
        <>
          <EmptyStateText>У вас пока нет ни одного сотрудника</EmptyStateText>
        </>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Сотрудник</th>
              <th>Должность</th>
              <th>Ставка</th>
            </tr>
          </thead>
          <TableBody>
            {users.map((item, index) => (
              <tr key={item.id} onClick={() => navigate(`${item.id}`)}>
                <td>{item.name}</td>
                <td>{item.role}</td>
                <td>{item.rate}</td>
              </tr>
            ))}
          </TableBody>
        </Table>
      )}
      <Button
        onClick={() => navigate(`/restaurants/${restaurantId}/new-user`)}
        type='button'
      >
        Добавить
      </Button>
    </Container>
  )
}

export default UsersTable

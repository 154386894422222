import AuthService from '@/services/auth/auth.service'
import { IUserDto } from '@/types/user'
import { useMutation } from '@tanstack/react-query'

const useCreateUser = (user: IUserDto, onSuccess: () => void) => {
  const { mutate } = useMutation(
    ['create user'],
    () => AuthService.register(user),
    {
      onSuccess
    }
  )

  return mutate
}

export default useCreateUser

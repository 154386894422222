import styled from 'styled-components'
import Colors from '@/shared/colors'
import Typography from '@/shared/typography'
import breakpoint from '@/shared/breakpoint'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TimePeriods = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const TimePeriod = styled.div<{ isActive?: boolean }>`
  ${Typography.Tiny};
  margin-right: 16px;
  color: ${Colors.Blue300};
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? 600 : 500)};
`

export const StatisticsWrapper = styled.div`
  margin-bottom: 32px;
`

export const Charts = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Menu = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;

  ${breakpoint.Mobile`
    display: flex;
  `}
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: ${Colors.White};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`

export const MenuItemTitle = styled.div`
  ${Typography.Tiny};
`

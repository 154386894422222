import breakpoint from '@/shared/breakpoint'
import colors from '@/shared/colors'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 220px;
  height: 220px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin-right: 16px;
  background-color: ${colors.White};
  cursor: pointer;

  ${breakpoint.Mobile`
    padding: 16px;
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    margin-right: 0;
  `}

  &:last-child {
    margin: 0;
  }
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
`

export const Address = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
`

import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import MainLayout from '@/components/MainLayout'
import useProducts from '@/hooks/products/useProducts'
import ProductsTable from '@/components/Tables/ProductsTable'
import Input from '@/components/Input'
import { IProduct } from '@/types/product'

const Products: React.FC = () => {
  const { products } = useProducts()
  const [search, setSearch] = useState('')
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([])

  useEffect(() => {
    if (search && products) {
      setFilteredProducts(
        products.filter(item =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      )
    } else if (products) {
      setFilteredProducts(products)
    }
  }, [search, products])

  return (
    <MainLayout>
      <Container>
        <Input
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder='Поиск по названию'
        />
        <ProductsTable products={filteredProducts} />
      </Container>
    </MainLayout>
  )
}

export default Products

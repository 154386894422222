import breakpoint from '@/shared/breakpoint'
import colors from '@/shared/colors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: ${colors.Gray100};

  ${breakpoint.Mobile`
    padding: 20px;
  `}
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint.Mobile`
    flex-direction: column;
  `}
`

export const AddRestaurantCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  width: 220px;
  height: 220px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin-right: 16px;
  background-color: ${colors.White};
  color: ${colors.Gray600};
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  ${breakpoint.Mobile`
    width: auto;
    height: auto;
    padding: 16px;
  `}
`

export const IconWrapper = styled.div`
  margin-bottom: 16px;

  ${breakpoint.Mobile`
    margin-bottom: 0;
  `}
`

export const AddRestaurantCardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;

  ${breakpoint.Mobile`
    display: none;
  `}
`

import Button from '@/components/Button'
import { FC, useEffect, useState } from 'react'
import MainLayout from '@/components/MainLayout'
import { useNavigate, useParams } from 'react-router'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import { IProductTransitionsReportForm } from '@/types/product.transitions.report'
import useProducts from '@/hooks/products/useProducts'
import Input from '@/components/Input'

import {
  Container,
  Content,
  Title,
  ProductTransitionsList,
  ProductTransitionItem,
  InputWrapper,
  ProductName,
  ProductAmount,
  ProductTransitionsHeader,
  ProductTransitionsHeaderItem
} from './styles'

import useProductTransitionsReportById from '@/hooks/productTransitionsReports/useProductTransitionsReportById'
import useUpdateProductTransitionsReport from '@/hooks/productTransitionsReports/useUpdateProductTransitionsReport'
import { format } from 'date-fns'
import {
  IProductTransitionForm,
  IProductTransitionDto
} from '@/types/product.transition'

const ApproveProductTransition: FC = () => {
  let { reportId } = useParams<{ reportId: string }>() || null
  const navigate = useNavigate()
  const { products } = useProducts()
  const restaurantId = useRestaurantUrlId()

  const [currentReport, setCurrentReport] =
    useState<IProductTransitionsReportForm>({
      date: new Date().toISOString(),
      productTransitions: [],
      status: 'pending',
      senderRestaurantId: parseInt(restaurantId || '0'),
      receiverRestaurantId: parseInt(restaurantId || '0') + 1
    })

  const { report, isLoading } = useProductTransitionsReportById(
    reportId || null
  )

  const updateReport = useUpdateProductTransitionsReport(() =>
    navigate(`/restaurants/${restaurantId}/product-transitions`)
  )

  useEffect(() => {
    if (!isLoading && report) {
      setCurrentReport({
        date: report.date,
        status: report.status,
        senderRestaurantId: report.senderRestaurant.id,
        receiverRestaurantId: report.receiverRestaurant.id,
        productTransitions: report.productTransitions.map(item => ({
          id: item.id,
          amount: item.amount.toString(),
          updatedAmount: item.updatedAmount?.toString() || '',
          productId: item.product.id
        }))
      })
    }
  }, [report, isLoading])

  const handleButtonClick = async () => {
    if (!currentReport.productTransitions.length) return
    updateReport({
      id: reportId || null,
      report: {
        ...currentReport,
        status: 'approved',
        productTransitions: transformProductTransitions(
          currentReport.productTransitions
        )
      }
    })
  }

  const productTypeUnit = (type: 'weight' | 'volume' | 'count') =>
    ({
      weight: 'кг',
      volume: 'л',
      count: 'шт'
    }[type])

  const transformProductTransitions = (
    transitions: IProductTransitionForm[]
  ): IProductTransitionDto[] => {
    return transitions
      .map(item => ({
        ...item,
        amount: parseFloat(item.amount || '0'),
        updatedAmount:
          item.updatedAmount !== null ? parseFloat(item.updatedAmount) : null
      }))
      .filter(item => item.amount !== 0)
  }

  if (!products) return null

  return (
    <MainLayout>
      <Container>
        <Content>
          <Title>{format(new Date(currentReport.date), 'dd.MM.yyyy')}</Title>

          {!!currentReport.productTransitions.length && (
            <ProductTransitionsHeader>
              <ProductTransitionsHeaderItem>
                Продукт
              </ProductTransitionsHeaderItem>
              <ProductTransitionsHeaderItem>
                Количество
              </ProductTransitionsHeaderItem>
              <ProductTransitionsHeaderItem>
                Изменение
              </ProductTransitionsHeaderItem>
            </ProductTransitionsHeader>
          )}
          <ProductTransitionsList>
            {currentReport.productTransitions.map(productTransition => (
              <ProductTransitionItem key={productTransition.productId}>
                <ProductName>
                  {
                    products.find(
                      product => product.id === productTransition.productId
                    )?.name
                  }
                </ProductName>
                <ProductAmount>
                  {productTransition.amount}
                  {productTypeUnit(
                    products.find(
                      product => product.id === productTransition.productId
                    )?.unit || 'weight'
                  )}
                </ProductAmount>
                <InputWrapper>
                  <Input
                    name='updatedAmount'
                    label={productTypeUnit(
                      products.find(
                        product => product.id === productTransition.productId
                      )?.unit || 'weight'
                    )}
                    value={productTransition.updatedAmount?.toString() || ''}
                    onChange={e => {
                      const newProductTransitions =
                        currentReport.productTransitions.map(item => {
                          if (item.productId === productTransition.productId) {
                            return {
                              ...item,
                              updatedAmount: e.target.value.replace(',', '.')
                            }
                          }
                          return item
                        })

                      setCurrentReport(report => ({
                        ...report,
                        productTransitions: newProductTransitions
                      }))
                    }}
                  />
                </InputWrapper>
              </ProductTransitionItem>
            ))}
          </ProductTransitionsList>
          <Button onClick={handleButtonClick}>Подтвердить</Button>
        </Content>
      </Container>
    </MainLayout>
  )
}

export default ApproveProductTransition

import React, { FC } from 'react'
import { Label, Container, InputField } from './styles'

interface InputProps {
  label?: string
  type?: string
  name?: string
  placeholder?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input: FC<InputProps> = ({
  label,
  type,
  name,
  placeholder,
  value,
  onChange
}) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <InputField
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </Container>
  )
}

export default Input

import React from 'react'
import './App.css'
import Login from '@/views/Login'
import AuthProvider from '@/providers/AuthProvider'
import Dashboard from '@/views/Dashboard'
import { UserContextProvider } from '@/context/user.context'
import Restaurants from '@/views/Restaurants'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import NewRestaurant from '../Restaurants/NewRestaurant/NewRestaurant'
import EditRestaurant from '../Restaurants/EditRestaurant'
import Users from '../Users'
import EditUser from '../Users/EditUser'
import NewUser from '../Users/NewUser'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Products from '../Products'
import EditProduct from '../Products/EditProduct'
import NewProduct from '../Products/NewProduct'
import ProductTransitions from '../ProductTransition'
import ProductTransitionView from '../ProductTransition/ProductTransitionView'
import EditProductTransition from '../ProductTransition/EditProductTransition'
import ApproveProductTransition from '../ProductTransition/ApproveProductTransition'
import CheckRole from '@/providers/CheckRole'
import TimePeriodReport from '../ProductTransition/TimePeriodReport'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <UserContextProvider>
          <AuthProvider>
            <CheckRole>
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route
                  path='/'
                  element={<Navigate to='/restaurants' replace />}
                />
                <Route path='/restaurants' element={<Restaurants />} />
                <Route path='/edit-restaurant' element={<NewRestaurant />} />
                <Route
                  path='/edit-restaurant/:restaurantId'
                  element={<EditRestaurant />}
                />

                <Route
                  path='restaurants/:restaurantId'
                  element={<Dashboard />}
                />
                <Route
                  path='restaurants/:restaurantId/users'
                  element={<Users />}
                />
                <Route
                  path='restaurants/:restaurantId/new-user'
                  element={<NewUser />}
                />
                <Route
                  path='restaurants/:restaurantId/users/:userId'
                  element={<EditUser />}
                />

                <Route
                  path='restaurants/:restaurantId/products'
                  element={<Products />}
                />
                <Route
                  path='restaurants/:restaurantId/products/:productId'
                  element={<EditProduct />}
                />
                <Route
                  path='restaurants/:restaurantId/products/new/product'
                  element={<NewProduct />}
                />

                <Route
                  path='restaurants/:restaurantId/product-transitions'
                  element={<ProductTransitions />}
                />

                <Route
                  path='restaurants/:restaurantId/product-transitions/:reportId'
                  element={<ProductTransitionView />}
                />

                <Route
                  path='restaurants/:restaurantId/product-transitions/edit/:reportId'
                  element={<EditProductTransition />}
                />

                <Route
                  path='restaurants/:restaurantId/product-transitions/approve/:reportId'
                  element={<ApproveProductTransition />}
                />
                <Route
                  path='restaurants/:restaurantId/product-transitions/time-period-report'
                  element={<TimePeriodReport />}
                />
              </Routes>
            </CheckRole>
          </AuthProvider>
        </UserContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App

import Input from '@/components/Input'
import { FC, useEffect, useState } from 'react'
import { Inputs } from './styles'
import { IUserDto, Role, RoleOption, Value } from '@/types/user'
import useRestaurants from '@/hooks/restaurants/useRestaurants'
import Select from '@/components/Select'

interface EditUserFormProps {
  currentUser: IUserDto
  onChange: (user: IUserDto) => void
  showPassword?: boolean
}

const rolesWithName: Record<Role, Value> = {
  admin: 'Администратор',
  operator: 'Оператор',
  accountant: 'Бухгалтер',
  manager: 'Менеджер',
  cook: 'Повар',
  chef: 'Шеф-повар'
}

const EditUserForm: FC<EditUserFormProps> = ({
  currentUser,
  onChange,
  showPassword
}) => {
  const { isLoading, restaurants } = useRestaurants()
  const [restaurantsOptions, setRestaurantsOptions] = useState<
    { value: string; label: string }[]
  >([])
  const rolesOptions: RoleOption[] = Object.entries(rolesWithName).map(
    ([key, value]) => ({
      value: key as Role,
      label: value
    })
  )

  useEffect(() => {
    if (!isLoading && restaurants) {
      setRestaurantsOptions(
        restaurants.map(restaurant => ({
          value: restaurant.id.toString(),
          label: restaurant.name || ''
        }))
      )
    }
  }, [restaurants, isLoading])

  return (
    <>
      <Inputs>
        <Input
          label='Имя Фамилия'
          name='name'
          value={currentUser.name}
          onChange={e =>
            onChange({
              ...currentUser,
              [e.target.name]: e.target.value
            })
          }
        />
        <Select
          name='role'
          label='Должность'
          options={rolesOptions}
          value={
            rolesOptions?.find(role => role.value === currentUser.role) ||
            rolesOptions[0]
          }
          onChange={option =>
            onChange({
              ...currentUser,
              role: option?.value as Role
            })
          }
        />
        <Input
          label='Ставка'
          name='rate'
          type='number'
          value={currentUser.rate?.toString()}
          onChange={e =>
            onChange({
              ...currentUser,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
        <Select
          name='restaurantId'
          label='Заведение'
          options={restaurantsOptions}
          value={
            restaurantsOptions?.find(
              restaurant =>
                parseInt(restaurant.value) === currentUser.restaurantId
            ) || restaurantsOptions[0]
          }
          onChange={option =>
            onChange({
              ...currentUser,
              restaurantId: parseInt(option?.value || '0')
            })
          }
        />
        <Input
          label='Email'
          name='email'
          value={currentUser.email}
          onChange={e =>
            onChange({
              ...currentUser,
              [e.target.name]: e.target.value
            })
          }
        />
        {showPassword && (
          <Input
            label='Пароль'
            name='password'
            value={currentUser.password}
            onChange={e =>
              onChange({
                ...currentUser,
                [e.target.name]: e.target.value
              })
            }
          />
        )}
      </Inputs>
    </>
  )
}

export default EditUserForm

import ProductTransitionsReportService from '@/services/productTransitionsReport/product.transition.service'
import { useMutation } from '@tanstack/react-query'

const useDeleteProductTransitionsReport = (onSuccess?: () => void) => {
  const { mutate } = useMutation({
    mutationFn: ({ id }: { id: string | null }) =>
      ProductTransitionsReportService.delete(id),
    onSuccess
  })

  return mutate
}

export default useDeleteProductTransitionsReport

import React, { FC } from 'react'
import { Container, ContainerAligned, Dot } from './styles'

const Spinner: FC = () => {
  return (
    <Container>
      {Array(8)
        .fill(1)
        .map((_, i) => (
          <Dot key={i} />
        ))}
    </Container>
  )
}

export const SpinnerAligned: FC<{ align: 'left' | 'right' | 'center' }> = ({
  align
}) => {
  return (
    <ContainerAligned align={align}>
      <Spinner />
    </ContainerAligned>
  )
}

export default Spinner

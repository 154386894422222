import React, { FC, useState } from 'react'
import ReactSelect, { ActionMeta } from 'react-select'
import { Container, Label } from './styles'

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]

type Option = {
  value: string
  label: string
}

interface SelectProps {
  name: string
  options: Option[]
  onChange(option: Option | null, actionMeta: ActionMeta<Option>): void
  value: Option | null
  label: string
}

const Select: FC<SelectProps> = ({ name, options, value, label, onChange }) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <ReactSelect
        name={name}
        options={options}
        value={value}
        onChange={onChange}
      />
    </Container>
  )
}

export default Select

import React from 'react'

import UsersTable from '@/components/Tables/UsersTable'

import { Container } from './styles'
import MainLayout from '@/components/MainLayout'
import useRestaurantId from '@/hooks/restaurants/useRestaurantUrlId'
import useRestaurantById from '@/hooks/restaurants/useRestaurantById'

const Users: React.FC = () => {
  const restaurantId = useRestaurantId()
  const { restaurant } = useRestaurantById(restaurantId)

  return (
    <MainLayout>
      <Container>
        {restaurant?.users && <UsersTable users={restaurant?.users} />}
      </Container>
    </MainLayout>
  )
}

export default Users

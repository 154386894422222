import instance from '../../api/api.interceptor'
import { IUser, IUserDto } from '../../types/user'

const UserService = {
  async getAll() {
    return instance<IUser[]>({
      url: `/users`,
      method: 'GET'
    })
  },

  async getProfile(id: string | null) {
    if (!id) return null
    return instance<IUser>({
      url: `/users/${id}`,
      method: 'GET'
    })
  },

  async create(user: IUserDto) {
    return instance<IUser>({
      url: `/users`,
      method: 'POST',
      data: user
    })
  },

  async update(id: string | null, user: IUserDto) {
    if (!id) return null
    return instance<IUser>({
      url: `/users/${id}`,
      method: 'PUT',
      data: user
    })
  },

  async delete(id: string | null) {
    if (!id) return null
    return instance<IUser>({
      url: `/users/${id}`,
      method: 'DELETE'
    })
  }
}

export default UserService

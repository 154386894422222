import colors from '@/shared/colors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 4px;
  color: ${colors.Gray500};
`

import { FC, useEffect, useState } from 'react'
import Select from '@/components/Select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { IProductTransitionsReportForm } from '@/types/product.transitions.report'
import useRestaurants from '@/hooks/restaurants/useRestaurants'

import { Inputs, DatePickerWrapper } from './styles'

interface EditProductTransitionReportFormProps {
  currentProductTransitionReport: IProductTransitionsReportForm
  onChange: (productTransitionReport: IProductTransitionsReportForm) => void
}

const EditProductTransitionReportForm: FC<
  EditProductTransitionReportFormProps
> = ({ currentProductTransitionReport, onChange }) => {
  const { isLoading, restaurants } = useRestaurants()
  const [restaurantsOptions, setRestaurantsOptions] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    if (!isLoading && restaurants) {
      setRestaurantsOptions(
        restaurants
          .map(restaurant => ({
            value: restaurant.id.toString(),
            label: restaurant.name || ''
          }))
          .filter(
            restaurant =>
              restaurant.value !==
              currentProductTransitionReport.senderRestaurantId.toString()
          )
      )
    }
  }, [restaurants, isLoading])

  return (
    <>
      <Inputs>
        <DatePickerWrapper>
          <DatePicker
            dateFormat={'dd.MM.yyyy'}
            selected={new Date(currentProductTransitionReport.date)}
            onChange={date =>
              onChange({
                ...currentProductTransitionReport,
                date: date?.toISOString() || new Date().toISOString()
              })
            }
          />
        </DatePickerWrapper>

        <Select
          name='receiverRestaurantId'
          label='Получатель'
          options={restaurantsOptions}
          value={
            restaurantsOptions?.find(
              restaurant =>
                parseInt(restaurant.value) ===
                currentProductTransitionReport.receiverRestaurantId
            ) ||
            restaurantsOptions.find(
              restaurant =>
                restaurant.value !==
                currentProductTransitionReport.senderRestaurantId.toString()
            ) ||
            null
          }
          onChange={option =>
            onChange({
              ...currentProductTransitionReport,
              receiverRestaurantId: parseInt(option?.value || '0')
            })
          }
        />
      </Inputs>
    </>
  )
}

export default EditProductTransitionReportForm

import { Role } from '@/types/user'
import { ItemIconClients, ItemIconStatistics } from './styles'

export type NavigationItem = {
  name: string
  url: string
  icon: React.FC
}

export const navigation = (
  restaurantId: string | null,
  role: Role
): NavigationItem[] => {
  const items: NavigationItem[] = {
    admin: [
      {
        name: 'Главная',
        url: `/restaurants/${restaurantId}`,
        icon: ItemIconClients
      },
      {
        name: 'Рестораны',
        url: `/restaurants`,
        icon: ItemIconClients
      },
      {
        name: 'Сотрудники',
        url: `/restaurants/${restaurantId}/users`,
        icon: ItemIconClients
      },
      {
        name: 'Продукты',
        url: `/restaurants/${restaurantId}/products`,
        icon: ItemIconClients
      },
      {
        name: 'Перемещения продуктов',
        url: `/restaurants/${restaurantId}/product-transitions`,
        icon: ItemIconClients
      }
      // {
      //   name: 'Ежедневные отчеты',
      //   url: `/restaurants/${restaurantId}/reports`,
      //   icon: ItemIconClients
      // }
    ],
    operator: [
      {
        name: 'Перемещения продуктов',
        url: `/restaurants/${restaurantId}/product-transitions`,
        icon: ItemIconClients
      }
    ],
    chef: [
      {
        name: 'Продукты',
        url: `/restaurants/${restaurantId}/products`,
        icon: ItemIconClients
      },
      {
        name: 'Перемещения продуктов',
        url: `/restaurants/${restaurantId}/product-transitions`,
        icon: ItemIconClients
      }
    ],
    cook: [
      {
        name: 'Перемещения продуктов',
        url: `/restaurants/${restaurantId}/product-transitions`,
        icon: ItemIconClients
      }
    ],
    accountant: [],
    manager: []
  }[role]
  return items
}

import breakpoint from '@/shared/breakpoint'
import colors from '@/shared/colors'
import typography from '@/shared/typography'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;

  ${breakpoint.Mobile`
    padding: 0;
  `}
`

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${colors.White};
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 32px;

  ${breakpoint.Mobile`
    padding: 20px;
  `}
`

export const Title = styled.div`
  ${typography.H6};
  margin-bottom: 16px;

  ${breakpoint.Mobile`
    font-size: 22px;
    line-height: 28px;
  `}
`

export const Inputs = styled.div`
  margin-bottom: 16px;
`

export const ProductTransitionsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ProductTransitionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
  align-self: center;
  margin-bottom: 32px;
`

export const InputWrapper = styled.div`
  max-width: 64px;
  margin: 0 24px;

  ${breakpoint.Mobile`
    margin: 0 8px;
  `}
`

export const SaveButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const IconWrapper = styled.div`
  margin-top: 12px;
  cursor: pointer;
`

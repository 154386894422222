import ProductService from '@/services/product/product.service'
import { IProductDto } from '@/types/product'
import { useMutation } from '@tanstack/react-query'

const useCreateProduct = (product: IProductDto, onSuccess: () => void) => {
  const { mutate } = useMutation(
    ['create product'],
    () => ProductService.create(product),
    {
      onSuccess
    }
  )

  return mutate
}

export default useCreateProduct

import UserService from '@/services/user/user.service'
import { useQuery } from '@tanstack/react-query'

const useUserById = (id: string | null) => {
  const { isLoading, data } = useQuery(['user by id'], async () => {
    return UserService.getProfile(id)
  })

  return { isLoading, user: data?.data }
}

export default useUserById

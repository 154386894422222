import RestaurantCard from '@/components/RestaurantCard'
import useRestaurants from '@/hooks/restaurants/useRestaurants'
import { FC } from 'react'

import {
  Container,
  Content,
  AddRestaurantCard,
  IconWrapper,
  AddRestaurantCardTitle
} from './styles'
import { Icon24 } from '@/components/Icon'
import { useNavigate } from 'react-router'
import MainLayout from '@/components/MainLayout'

const Restaurants: FC = () => {
  const { isLoading, restaurants } = useRestaurants()
  const navigate = useNavigate()

  return (
    <Container>
      <h1>Рестораны</h1>

      <Content>
        {restaurants &&
          restaurants.map(restaurant => (
            <RestaurantCard key={restaurant.id} restaurant={restaurant} />
          ))}
        <AddRestaurantCard onClick={() => navigate('/edit-restaurant')}>
          <IconWrapper>
            <Icon24.Plus />
          </IconWrapper>
          <AddRestaurantCardTitle>Новый ресторан</AddRestaurantCardTitle>
        </AddRestaurantCard>
      </Content>
    </Container>
  )
}

export default Restaurants

import RestaurantService from '@/services/restaurant/restaurant.service'
import { IRestaurantDto } from '@/types/restaurant'
import { useMutation } from '@tanstack/react-query'

const useUpdateRestaurant = (
  id: string | null,
  restaurant: IRestaurantDto,
  onSuccess?: () => void
) => {
  const { mutate } = useMutation(
    ['update restaurant'],
    () => RestaurantService.update(id, restaurant),
    {
      onSuccess
    }
  )

  return mutate
}

export default useUpdateRestaurant

import instance from '../../api/api.interceptor'
import {
  IProductTransitionsReport,
  IProductTransitionsReportDto
} from '@/types/product.transitions.report'

const ProductTransitionsReportService = {
  async getAll() {
    return instance<IProductTransitionsReport[]>({
      url: `/product-transitions/`,
      method: 'GET'
    })
  },

  async getAllReceived(restaurantId: string | null) {
    if (!restaurantId) return null
    return instance<IProductTransitionsReport[]>({
      url: `/product-transitions/receiver-reports/${restaurantId}`,
      method: 'GET'
    })
  },

  async getAllReceivedByPeriod(
    restaurantId: string | null,
    startDate: string,
    endDate: string
  ) {
    if (!restaurantId) return null
    return instance<IProductTransitionsReport[]>({
      url: `/product-transitions/receiver-reports/report-period/${restaurantId}`,
      method: 'POST',
      data: {
        startDate,
        endDate
      }
    })
  },

  async getAllSent(restaurantId: string | null) {
    if (!restaurantId) return null
    return instance<IProductTransitionsReport[]>({
      url: `/product-transitions/sender-reports/${restaurantId}`,
      method: 'GET'
    })
  },

  async getById(id: string | null) {
    if (!id) return null
    return instance<IProductTransitionsReport>({
      url: `/product-transitions/${id}`,
      method: 'GET'
    })
  },

  async create(senderId: string | null) {
    return instance<IProductTransitionsReport>({
      url: `/product-transitions/${senderId}`,
      method: 'POST'
    })
  },

  async update(id: string | null, report: IProductTransitionsReportDto) {
    if (!id) return null
    return instance<IProductTransitionsReport>({
      url: `/product-transitions/${id}`,
      method: 'PUT',
      data: report
    })
  },

  async delete(id: string | null) {
    if (!id) return null
    return instance<IProductTransitionsReport>({
      url: `/product-transitions/${id}`,
      method: 'DELETE'
    })
  }
}

export default ProductTransitionsReportService

import Input from '../../Input'
import { IRestaurantDto } from '@/types/restaurant'
import { FC } from 'react'
import { Inputs } from './styles'

interface EditRestaurantFormProps {
  currentRestaurant: IRestaurantDto
  onChange: (restaurant: IRestaurantDto) => void
}

const EditRestaurantForm: FC<EditRestaurantFormProps> = ({
  currentRestaurant,
  onChange
}) => {
  return (
    <>
      <Inputs>
        <Input
          label='Название'
          name='name'
          value={currentRestaurant.name}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: e.target.value
            })
          }
        />
        <Input
          label='Адрес'
          name='address'
          value={currentRestaurant.address}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: e.target.value
            })
          }
        />
        <Input
          label='Аренда'
          name='rentPrice'
          type='number'
          value={currentRestaurant.rentPrice?.toString()}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
        <Input
          label='Коммунальные платежи'
          name='communalPayments'
          value={currentRestaurant.communalPayments?.toString()}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
        <Input
          label='Стоимость телефонной связи'
          name='mobileConnectionPrice'
          value={currentRestaurant.mobileConnectionPrice?.toString()}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
        <Input
          label='Стоимость интернета'
          name='internetPrice'
          value={currentRestaurant.internetPrice?.toString()}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
        <Input
          label='Стоимость iiko'
          name='iikoPrice'
          value={currentRestaurant.iikoPrice?.toString()}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
        <Input
          label='Стоимость Smartomato'
          name='smartomatoPrice'
          value={currentRestaurant.smartomatoPrice?.toString()}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
        <Input
          label='Банковская комиссия'
          name='bankFee'
          value={currentRestaurant.bankFee?.toString()}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
        <Input
          label='Банковская комиссия онлайн'
          name='bankOnlineFee'
          value={currentRestaurant.bankOnlineFee?.toString()}
          onChange={e =>
            onChange({
              ...currentRestaurant,
              [e.target.name]: parseFloat(e.target.value)
            })
          }
        />
      </Inputs>
    </>
  )
}

export default EditRestaurantForm

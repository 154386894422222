import breakpoint from '@/shared/breakpoint'
import colors from '@/shared/colors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  margin-bottom: 24px;
`

export const PendingReports = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${breakpoint.Mobile`
    flex-direction: column;
  `}
`

export const PendingReportDelete = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;
`

export const PendingReport = styled.div`
  position: relative;
  width: 100%;
  max-width: 240px;
  padding: 20px;
  border-radius: 12px;
  background-color: ${colors.White};
  margin-right: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover ${PendingReportDelete} {
    display: block;
  }

  ${breakpoint.Mobile`
    max-width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
  `}
`

export const PendingReportTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const PendingReportBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PendingReportCount = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.Gray400};
`

export const PendingReportStatus = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 500;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    left: -12px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.Yellow300};
  }
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
`

import styled from 'styled-components'
import Colors from '../../shared/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Button = styled.div<{ isActive?: boolean }>`
  padding: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 50px;
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Gray300};
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${Colors.Blue300};
    color: ${Colors.White};
    border: 1px solid ${Colors.Blue300};
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  `}
`

import { css } from 'styled-components'

const typography = {
  H1: css`
    font-size: 54px;
    line-height: 54px;
  `,

  H2: css`
    font-size: 46px;
    line-height: 48px;
  `,

  H3: css`
    font-size: 38px;
    line-height: 40px;
  `,

  H4: css`
    font-size: 32px;
    line-height: 34px;
  `,

  H5: css`
    font-size: 28px;
    line-height: 30px;
  `,

  H6: css`
    font-size: 26px;
    line-height: 28px;
  `,

  Subhead: css`
    font-size: 22px;
    line-height: 28px;
  `,

  Body: css`
    font-size: 19px;
    line-height: 24px;
  `,

  Caps: css`
    font-size: 14px;
    letter-spacing: 2.2px;
    line-height: 20px;
    text-transform: uppercase;
  `,

  SmallCaps: css`
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 14px;
    text-transform: uppercase;
  `,

  Caption: css`
    font-size: 16px;
    line-height: 20px;
  `,

  Tiny: css`
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
  `,

  Button: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  `
}

export default typography

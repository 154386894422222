import Button from '@/components/Button'
import { FC, useState } from 'react'
import { Container, Content, Title, Inputs } from './styles'
import { IUserDto } from '@/types/user'
import MainLayout from '@/components/MainLayout'
import useCreateUser from '@/hooks/users/useCreateUser'
import { useNavigate } from 'react-router'
import useRestaurantUrlId from '@/hooks/restaurants/useRestaurantUrlId'
import EditUserForm from '@/components/Forms/EditUserForm'

const NewUser: FC = () => {
  const navigate = useNavigate()
  const restaurantId = parseInt(useRestaurantUrlId() || '0')

  const [currentUser, setCurrentUser] = useState<IUserDto>({
    name: '',
    email: '',
    password: '',
    role: undefined,
    restaurantId: restaurantId,
    rate: 0
  })
  const createUser = useCreateUser(currentUser, () =>
    navigate(`/restaurants/${restaurantId}/users`)
  )

  const handleButtonClick = async () => {
    if (!currentUser.name || !currentUser.password || !currentUser.email) return
    createUser()
  }

  return (
    <MainLayout>
      <Container>
        <Content>
          <Title>Добавить сотрудника</Title>
          <EditUserForm
            currentUser={currentUser}
            onChange={setCurrentUser}
            showPassword
          />
          <Button onClick={handleButtonClick}>Добавить</Button>
        </Content>
      </Container>
    </MainLayout>
  )
}

export default NewUser

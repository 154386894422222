import { IRestaurant } from '@/types/restaurant'
import { FC } from 'react'

import { Container, Title, Address } from './styles'
import { useNavigate } from 'react-router'

interface RestaurantCardProps {
  restaurant: IRestaurant
}

const RestaurantCard: FC<RestaurantCardProps> = ({ restaurant }) => {
  const navigate = useNavigate()

  return (
    <Container onClick={() => navigate(`${restaurant.id}`)}>
      <Title>{restaurant.name}</Title>
      <Address>{restaurant.address}</Address>
    </Container>
  )
}

export default RestaurantCard

import styled from 'styled-components'
import Typography from '@/shared/typography'
import Colors from '@/shared/colors'

interface ButtonProps {
  type?: 'button' | 'ghost'
  color?: 'green' | 'red'
}

const getBackgroundColor = (
  type?: 'button' | 'ghost',
  color?: 'green' | 'red'
): string => {
  if (type === 'ghost') {
    return 'none'
  }

  if (color === 'green') {
    return Colors.Green300
  }

  if (color === 'red') {
    return Colors.Red300
  }

  return Colors.Blue300
}

const getBorderColor = (
  type?: 'button' | 'ghost',
  color?: 'green' | 'red'
): string => {
  if (type === 'ghost') {
    return Colors.Blue300
  }

  if (color === 'green') {
    return Colors.Green300
  }

  if (color === 'red') {
    return Colors.Red300
  }

  return 'none'
}

const getColor = (
  type?: 'button' | 'ghost',
  color?: 'green' | 'red'
): string => {
  if (type === 'ghost' && !color) {
    return Colors.Blue300
  }

  if (type === 'ghost' && color === 'green') {
    return Colors.Green300
  }

  if (type === 'ghost' && color === 'red') {
    return Colors.Red300
  }

  return Colors.White
}

const getHoverBackgroundColor = (
  type?: 'button' | 'ghost',
  color?: 'green' | 'red'
): string => {
  if (type === 'ghost') {
    return Colors.Blue100
  }

  if (color === 'green') {
    return Colors.Green400
  }

  if (color === 'red') {
    return Colors.Red400
  }

  return Colors.Blue400
}

const Button = styled.div<ButtonProps>`
  ${Typography.Button}
  padding: 8px 12px;
  background-color: ${props => getBackgroundColor(props.type, props.color)};
  border: ${props => `1px solid ${getBorderColor(props.type, props.color)}`};
  color: ${props => getColor(props.type, props.color)};
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${props =>
      getHoverBackgroundColor(props.type, props.color)};
  }
`

export default Button

import ProductTransitionsReportService from '@/services/productTransitionsReport/product.transition.service'
import { IProductTransitionsReportDto } from '@/types/product.transitions.report'
import { useMutation } from '@tanstack/react-query'

const useUpdateProductTransitionsReport = (onSuccess?: () => void) => {
  const { mutate } = useMutation({
    mutationFn: ({
      id,
      report
    }: {
      id: string | null
      report: IProductTransitionsReportDto
    }) => ProductTransitionsReportService.update(id, report),
    onSuccess
  })

  return mutate
}

export default useUpdateProductTransitionsReport

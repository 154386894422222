import styled from 'styled-components'
import Colors from '../../shared/colors'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`

export const InputField = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 18px;
  outline: none;
  border-radius: 4px;
  border: 1px solid ${Colors.Gray300};
  outline: none;
`

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 4px;
  color: ${Colors.Gray500};
`

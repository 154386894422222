import Button from '@/components/Button'
import { IRestaurantDto } from '@/types/restaurant'
import { FC, useState } from 'react'
import { Container, Content, Title, Inputs } from './styles'
import useCreateRestaurant from '@/hooks/restaurants/useCreateRestaurant'
import EditRestaurantForm from '@/components/Forms/EditRestaurantForm'
import { useNavigate } from 'react-router'

const NewRestaurant: FC = () => {
  const navigate = useNavigate()
  const [currentRestaurant, setCurrentRestaurant] = useState<IRestaurantDto>({
    name: '',
    address: '',
    rentPrice: 0,
    communalPayments: 0,
    consumablesPrice: 0,
    mobileConnectionPrice: 0,
    internetPrice: 0,
    iikoPrice: 0,
    smartomatoPrice: 0,
    bankFee: 0,
    bankOnlineFee: 0
  })
  const createRestaurant = useCreateRestaurant(currentRestaurant, () =>
    navigate('/restaurants')
  )

  const handleButtonClick = async () => {
    if (!currentRestaurant.name || !currentRestaurant.address) return
    createRestaurant()
  }

  return (
    <Container>
      <Content>
        <Title>Создание нового ресторана</Title>
        <EditRestaurantForm
          currentRestaurant={currentRestaurant}
          onChange={setCurrentRestaurant}
        />
        <Button onClick={handleButtonClick}>Создать</Button>
      </Content>
    </Container>
  )
}

export default NewRestaurant

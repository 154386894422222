const colors = {
  White: '#ffffff',

  Blue100: '#e6f4ff',
  Blue200: '#cce8ff',
  Blue300: '#2196f3',
  Blue400: '#168ae6',
  Blue500: '#1976d2',

  Green300: '#25B869',
  Green400: '#22A860',
  Green500: '#00A042',

  Gray50: '#f8f9fc',
  Gray100: '#f2f5fa',
  Gray150: '#e6ebf4',
  Gray200: '#dde3f0',
  Gray300: '#cfd6e6',
  Gray400: '#b8becc',
  Gray500: '#98a1b3',
  Gray600: '#7a8599',
  Gray700: '#353a3f',

  Black100: '#4f5259',
  Black200: '#3d4047',
  Black300: '#262b33',
  Black400: '#0f141f',

  Red300: '#ff4c4c',
  Red400: '#f22222',

  Yellow150: '#FDC87A',
  Yellow300: '#f3b721',
  Yellow400: '#f09f13',
  Yellow500: '#e68f0e',

  Orange300: '#ffba19',
  Orange500: '#f68559',

  Indigo500: '#5863dd',
  Indigo600: '#363E96',
  Indigo700: '#21277F',
  Indigo800: '#151770',

  Purple: '#766FDE',
  Blue: '#66D3F5',
  Green: '#7DD7A0',
  Yellow: '#F5C57A',
  Google: '#d34836'
}

export default colors

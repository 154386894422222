import React, { useMemo } from 'react'
import Button from '@/components/Button'
import { useNavigate } from 'react-router'

import { Container, EmptyStateText, Table, TableBody } from './styles'

import { IProduct } from '@/types/product'
import { useAuth } from '@/hooks/useAuth'

interface ProductsTableProps {
  products: IProduct[]
}

const ProductsTable: React.FC<ProductsTableProps> = ({ products }) => {
  const navigate = useNavigate()
  const { user } = useAuth()

  const compare = (a: IProduct, b: IProduct) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const measurementSystem = (item: IProduct) => {
    switch (item.unit) {
      case 'weight':
        return 'кг'
      case 'volume':
        return 'л'
      case 'count':
        return 'шт'
      default:
        return 'кг'
    }
  }

  const productType = (product: IProduct) =>
    ({
      kitchen: 'Кухня',
      household: 'Хозы'
    }[product.type])

  const sortedProducts = useMemo(() => {
    switch (user?.role) {
      case 'admin':
        return products.sort(compare)
      case 'chef':
      case 'cook':
        return products.filter(item => item.type === 'kitchen').sort(compare)
      case 'operator':
        return products.filter(item => item.type === 'household').sort(compare)
    }
  }, [products, user?.role])

  return (
    <Container>
      {!sortedProducts?.length ? (
        <>
          <EmptyStateText>У вас пока нет ни одного продукта</EmptyStateText>
        </>
      ) : (
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>Название</th>
              <th>Цена</th>
              <th>Ед. измерения</th>
              <th>Тип</th>
            </tr>
          </thead>
          <TableBody>
            {sortedProducts?.map(item => (
              <tr key={item.id} onClick={() => navigate(`${item.id}`)}>
                <td>
                  <img src={item.imagePath} alt='' />
                </td>
                <td>{item.name}</td>
                <td>{item.price}</td>
                <td>{measurementSystem(item)}</td>
                <td>{productType(item)}</td>
              </tr>
            ))}
          </TableBody>
        </Table>
      )}
      <Button onClick={() => navigate(`new/product`)} type='button'>
        Добавить
      </Button>
    </Container>
  )
}

export default ProductsTable
